#
# Main script
#
# How to compile:
# Using Gulp - Have a look at README.md
# ----------------------------------------------------/



jQuery ($) ->

  # Lite easing extracted from jQuery Easing
  $.easing['jswing'] = $.easing['swing']
  $.extend $.easing,
    easeInOutQuad: (x, t, b, c, d) ->
      if ((t/=d/2) < 1) then c/2*t*t + b else -c/2 * ((--t)*(t-2) - 1) + b
    easeInOutCubic: (x, t, b, c, d) ->
        if ((t/=d/2) < 1) then c/2*t*t*t + b else c/2*((t-=2)*t*t + 2) + b




  App =

    wrapper: $('#js-site-wrapper')
    header: $('#js-site-header')
    headroom: ''
    is_dark_theme: false


    init: ->

      # Init anim on load
      $('#js-init-animation').fadeOut 500, 'easeInOutCubic', ->
        $('body').removeClass 'is--init-animation'

      # Init everything needed
      App.bind_events()
      App.reveal_anim()
      App.typing_carousel() if $('.js-typing-carousel').length


    # Binds differents events
    bind_events: ->

      # Init sticky header effect using Headroom lib
      App.header.headroom
        onUnpin: ->
          $('#navbar_menu').collapse 'hide' # Force menu collapse when using responsive menu
        onTop: ->
          $('#navbar_menu').collapse 'hide' # Force menu collapse when using responsive menu
          App.header.removeClass 'is--forced-one-theme-on-menu-open'

      # Return to top link and state
      return_to_top = $('.js-return-to-top')
      return_to_top.on 'click', (e) ->
        e.preventDefault()
        $('html, body').stop(true, true).animate { scrollTop: 0 }, 1500, 'easeInOutCubic'

      # Add a custom gradient color layer to a container
      if $('.js-has-gradient-layer').length
        $('.js-has-gradient-layer').each (i, e) ->
          gradient_start = App.hex_to_rgba $(@).attr('data-gradient-start'), '100'
          gradient_end = App.hex_to_rgba $(@).attr('data-gradient-end'), '40'
          $('<span class="gradient-layer-item"></span>')
            .css
              background: 'linear-gradient(to right, ' + gradient_start + ', ' + gradient_end + ')'
            .appendTo $(@)

      if $('.js-has-gradient-layer-full').length
        $('.js-has-gradient-layer-full').each (i, e) ->
          gradient_start = App.hex_to_rgba $(@).attr('data-gradient-start'), '100'
          gradient_end = App.hex_to_rgba $(@).attr('data-gradient-end'), '100'
          $('<span class="gradient-layer-item"></span>')
            .css
              background: 'linear-gradient(to right, ' + gradient_start + ', ' + gradient_end + ')'
            .appendTo $(@)

      # Add the first letter of the first paragraph to a data attribute (CSS style)
      if $('.has--first-p-child-initial').length
        $('.has--first-p-child-initial').each (i, e) ->
          first_paragr = $(@).find('p:first-child')
          first_paragr.attr 'data-initial', first_paragr.html().slice(0, 1).toLowerCase() if first_paragr


      # Toggle depending on scrollTop pos
      $(window).on 'scroll', (e) ->
        top_dist = $(window).scrollTop()

        # Switch the return-to-top button and the contact button
        if top_dist + window.innerHeight > $(document).height() - 50
          if !return_to_top.hasClass 'is--active'
            return_to_top.addClass 'is--active'
            $('.js-contact-btn').stop(true, true).fadeOut 125, 'easeInOutCubic', ->
              return_to_top.stop(true, true).fadeIn 125, 'easeInOutCubic'
        else
          if return_to_top.hasClass 'is--active'
            return_to_top.stop(true, true).fadeOut 125, 'easeInOutCubic', ->
              $('.js-contact-btn').stop(true, true).fadeIn 125, 'easeInOutCubic'
            return_to_top.removeClass 'is--active'

        # Force the red theme to the contact btn leaving top pos (homepage only)
        if $('body').hasClass('home')
          if top_dist > 25
            $('.js-contact-btn').addClass 'is--red-theme--forced'
          else
            $('.js-contact-btn').removeClass 'is--red-theme--forced'

      .triggerHandler 'scroll'


      # Handle responsive stuff
      $('#js-menu-m-toggler').on 'click', (e) ->
        if $(@).hasClass 'collapsed'
          App.header.addClass 'is--forced-one-theme-on-menu-open'
        else
          App.header.removeClass 'is--forced-one-theme-on-menu-open'
      $(window).on 'resize', () ->
        win_width = $(window).innerWidth()
        if win_width < 561
          App.header.addClass 'is--forced-one-theme'
        else
          App.header.removeClass 'is--forced-one-theme'
      .triggerHandler 'resize'


      # Avoid to trigger CSS transitions on page load (Bug from Edge/IE11)
      $('body').removeClass 'preload'


      # Avoid removing the dark theme if it is already active on page load
      App.is_dark_theme = App.wrapper.hasClass 'has--header-dark-theme'


      # Create the chapters list and the scroll tracking
      TOC.init() if $('.js-chapters').length


      # Init hero carousel (Homepage - Tiny Slider)
      if $('#js-slider-intro').length
        slider_home = tns
          container: '#js-slider-intro'
          mode: 'gallery'
          autoHeight: false
          items: 1
          controls: false
          nav: false
          swipeAngle: false
          speed: 1000
          mouseDrag: false
          touch: false
          loop: true
          autoplay: true
          autoplayTimeout: 8750
          autoplayButtonOutput: false




    # Numbers increment animation
    counting_nbr_anim: (el) ->
      if $(el).find('.js-counting-nbr').length && !$(el).hasClass('is--already-animated')
        $(el).addClass 'is--already-animated'
        $(el).find('.js-counting-nbr').each (i, e) ->
          counting_nbr = $(@)
          counter_val = parseInt counting_nbr.attr('data-nbr')

          $({percentage: 0}).stop(true).animate({percentage: counter_val}, {
            duration: 2000,
            easing: 'easeInOutCubic',
            step: ->
              count = Math.round(Math.round(this.percentage * 10) / 10)
              counting_nbr.html count
          }).promise().done ->
            counting_nbr.html counter_val


    reveal_anim: ->
      # Revealjs animation
      window.sr = ScrollReveal
        duration: 1000
        easing: 'cubic-bezier(0.165, 0.840, 0.440, 1)'
        scale: 1
        distance: '20px'
        viewFactor: 0.2

      # No animation reveal options
      # Useful to execute specific script. In thas case, number increment animation
      opts_static =
        duration: 0
        opacity: 1
        distance: '0px'
        cleanup: true
        beforeReveal: (el) ->
          App.counting_nbr_anim el

      if sr.isSupported()
        $('html').addClass 'is--reveal-ready'

        # Bind
        if $('.js-reveal-onscroll').length
          sr.reveal '.js-reveal-onscroll'
        if $('.js-reveal-onscroll-nodist').length
          sr.reveal '.js-reveal-onscroll-nodist',
            distance: '0px'
        if $('.js-reveal-onscroll-nodist-delayed').length
          sr.reveal '.js-reveal-onscroll-nodist-delayed',
            distance: '0px'
            delay: 500
        if $('.js-reveal-onscroll-delayed').length
          sr.reveal '.js-reveal-onscroll-delayed',
            delay: 500
        if $('.js-reveal-onscroll-delayed-2nd').length
          sr.reveal '.js-reveal-onscroll-delayed-2nd',
            delay: 1000
        if $('.js-reveal-onscroll-numbers').length
          sr.reveal '.js-reveal-onscroll-numbers', opts_static
        if $('.js-reveal-onscroll-mask-from-left').length
          sr.reveal '.js-reveal-onscroll-mask-from-left',
            origin: 'left'
            distance: '100%'
            opacity: 1


    # Text typing animation (carousel)
    typing_carousel: ->

      txtRotate = (el, toRotate, period) ->
        this.toRotate = toRotate
        this.el = el
        this.loopNum = 0
        this.period = parseInt(period, 10) || 2000
        this.txt = ''
        this.tick()
        this.isDeleting = false

      txtRotate.prototype.tick = () ->
        i = this.loopNum % this.toRotate.length
        fullTxt = this.toRotate[i]

        if this.isDeleting
          this.txt = fullTxt.substring(0, this.txt.length - 1)
        else
          this.txt = fullTxt.substring(0, this.txt.length + 1)

        # Update text
        this.el.html '<span class="wrapper-typing">' + this.txt + '</span>'

        that = this
        delta = 300 - Math.random() * 100
        delta /= 2 if this.isDeleting

        if !this.isDeleting && this.txt == fullTxt
          delta = this.period
          this.isDeleting = true
        else
          if this.isDeleting && this.txt == ''
            this.isDeleting = false
            this.loopNum++
            delta = 500

        # Loop effect
        setTimeout ->
          that.tick()
        , delta

      # Init effect
      $('.js-typing-carousel').each (i, e) ->
        d_rotate = JSON.parse $(@).attr('data-rotate')
        d_period = $(@).attr 'data-period'
        new txtRotate($(@), d_rotate, d_period) if d_rotate


    # Convert HEX color to RGBA format
    hex_to_rgba: (hex, opacity) ->
      hex = hex.replace '#', ''

      # Complete code if a short HEX format is provided
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2] if hex.length == 3

      # Parse
      r = parseInt(hex.substring(0,2), 16)
      g = parseInt(hex.substring(2,4), 16)
      b = parseInt(hex.substring(4,6), 16)

      return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity/100 + ')'




  # Namespace: the table of contents per page (chapters/sections list and scroll tracking)
  TOC =

    elem: $('#js-toc')

    init: ->
      chapters = $('.js-chapters')
      chapters_count = chapters.length
      # Get data and create the table of contents
      chapters.each (i, e) ->
        chapter_title = $(@).attr 'data-title'
        chapter_ui_theme = $(@).attr 'data-side-ui-theme'
        chapter_aside_content = $(@).attr 'data-copy-aside-content'
        if (typeof(chapter_aside_content) == 'undefined')
          chapter_aside_content = ''
        chapter_ui_special = $(@).attr 'data-ui-special'
        # Titles
        toc_item = '<li class="toc-item js-toc-item" data-aside-content="'+chapter_aside_content+'" data-ui="' +
                    chapter_ui_theme + '" data-ui-special="' +
                    chapter_ui_special + '" data-chapter="' + i + '">' + chapter_title + '</li>'
        # Add the title to the TOC DOM
        $(toc_item).appendTo TOC.elem

      # Track the active chapter on scroll
      scroll_offset = 400
      $(window).off 'scroll.toc_scroll'
      $(window).on 'scroll.toc_scroll', (e) ->
        scroll_t = $(window).scrollTop()
        if $('.js-chapters').length
          first_chapter_pos = $('.js-chapters').eq(0).position().top - scroll_offset
          # Assign active class to TOC item while scolling or just disable it
          if first_chapter_pos > scroll_t
            TOC.elem.removeClass 'is--shown'
          else
            TOC.elem.addClass 'is--shown'
            $('.js-chapters').each (i) ->
              current_chapter = $(this)
              if (current_chapter.position().top - scroll_offset) <= scroll_t
                # Is this the last chapter?
                if current_chapter.next('.js-chapters').length
                  # Is the next chapter the current chapter?
                  if ((current_chapter.next('.js-chapters').position().top - scroll_offset) > scroll_t)
                    TOC.update_chapter(current_chapter, i)
                else
                  # Always update the last chapter
                  TOC.update_chapter(current_chapter, i)
      .triggerHandler 'scroll'

    update_chapter: (current_chapter, index) ->
      if !current_chapter.hasClass 'is--active'
        $('.js-chapters').removeClass 'is--active'
        current_chapter.addClass 'is--active'
        $('.js-toc-item.is--active').removeClass 'is--active'
        new_active_chapter = $('.js-toc-wrapper .js-toc-item:nth-child(' + (index + 1) + ')')
        new_active_chapter.addClass 'is--active'

        # Update side UI color theme (4 possibilities: blue, red, purple or default white)
        if new_active_chapter.attr 'data-ui'
          ui_theme = new_active_chapter.attr 'data-ui'
          if new_active_chapter.attr('data-ui-special')
            ui_special = new_active_chapter.attr('data-ui-special')
          else
            ui_special = ''

          switch ui_theme
            when 'blue' # Blue theme
              $('.js-copy-aside p').css
                color: '#fff'
              $('.js-toc-wrapper .toc-item').css
                color: '#fff'
              $('.js-social-aside svg').css
                fill: '#fff'
              $('.js-contact-btn, .js-return-to-top').removeClass('is--red-theme is--red-full-theme is--purple-theme').addClass 'is--blue-theme'

            when 'red' # Red theme
              $('.js-copy-aside p').css
                color: '#F42F62'
              $('.js-toc-wrapper .toc-item').css
                color: '#F42F62'
              $('.js-social-aside svg').css
                fill: '#F42F62'
              $('.js-contact-btn, .js-return-to-top').removeClass('is--blue-theme is--red-full-theme is--purple-theme').addClass 'is--red-theme'

            when 'purple' # Purple theme
              $('.js-copy-aside p').css
                color: '#C4A2FC'
              $('.js-toc-wrapper .toc-item').css
                color: '#C4A2FC'
              $('.js-social-aside svg').css
                fill: '#C4A2FC'
              $('.js-contact-btn, .js-return-to-top').removeClass('is--red-theme is--red-full-theme is--blue-theme').addClass 'is--purple-theme'
            when 'custom' # Custom theme
              $('.js-copy-aside p').css
                color: ''
              $('.js-toc-wrapper .toc-item').css
                color: ''
              $('.js-social-aside svg').css
                fill: ''
              $('.js-contact-btn, .js-return-to-top').removeClass('is--red-theme is--purple-theme is--red-full-theme is--blue-theme').addClass 'is--custom-theme'
            else # (default) White theme
              $('.js-copy-aside p').css
                color: '#FFFFFF'
              $('.js-toc-wrapper .toc-item').css
                color: '#FFFFFF'
              $('.js-social-aside svg').css
                fill: '#FFFFFF'
              switch ui_special
                when 'intro-home' # Special contact btn theme on the introduction block (homepage)
                  $('.js-contact-btn').removeClass('is--purple-theme is--red-theme is--blue-theme').addClass 'is--red-full-theme'
                else
                  $('.js-contact-btn, .js-return-to-top').removeClass('is--purple-theme is--red-full-theme is--red-theme is--blue-theme')
          if new_active_chapter.attr 'data-aside-content'
            copy_aside_content = new_active_chapter.attr 'data-aside-content'
            $('.js-copy-aside-content').text(copy_aside_content)
          else $('.js-copy-aside-content').text('')





  # Init
  $ ->
    App.init()
